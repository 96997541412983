@import './tutorial.css';

@font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url(./fonts/b-reg.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

/* style everything except .add-friends-select__control */

h2 svg:hover {
  cursor: pointer;
}

:root {
  --bs-link-color: #ee454096;
}

body {
  font-family: 'MyFont';
  background-color: #F2F2F2;
  margin-bottom: 100px;
}


.header svg {
  color: #ee454096;
}

.affirmations-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 0px;
  margin-top: 15px;
}

.affirmations-header h3:nth-child(2) {
  padding-right: 10px;
}

.affirmations-header h3:nth-child(1) {
  padding-left: 10px;
}

.affirmations-header .active-tab {
  opacity: 0.8;
  transform: scale(1.05);
}

.affirmations-header :not(.active-tab) {
  opacity: 0.2;
  transform: scale(0.9);
  transition: all 0.2s;
}

.affirmations-header h3 {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 1.5em;
  opacity: 0.2;
  transition: all 0.2s;
}

.affirmations-header h3:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.affirmations-header h3:active {
  transform: scale(0.9);
}

.affirm-button {
  border-radius: 15px;
  transition: all 0.2s;
}

.affirm-button:hover {
  background: #ee454096 !important;
  transform: scale(1.05);
  cursor: pointer;
  box-shadow: 1px 1px 10px #ee45402e;
  border-color: #ee454096;
}

.snack-info {
  color: black !important;
}

.snack-info h4 {
  text-transform: lowercase !important;
}

.affirm-button-hide {
  position: fixed;
  bottom: -50px !important;
}

.custom-recipient__control {
  padding: 0 !important;
  padding-left: 15px !important;
  font-size: 1.1em;
}

#devious {
  z-index: -1 !important;
}

.devious-tag svg {
  margin-left: 5px;
}

.return-tag svg {
  margin-right: 5px;
}

.devious-button {
  font-weight: bold;
  border-radius: 15px;
  padding: 5px 15px;
  border: 3px solid #ee45405e;
  border-color: #ee45405e;
  background: #ffffff52;
  color: #ee454096;
  transition: all 0.2s;
}

.devious-button:active {
  transform: scale(0.9) !important;
  color: #ee454096;
  border-color: #ee454096 !important;
}

.devious-button:hover {
  background: #ee454096 !important;
  cursor: pointer;
  box-shadow: 1px 1px 10px #ee45402e;
  border-color: #ee454096;
}

.devious {
  margin-left: auto;
}

.poll-results-header {
  display: flex;
  align-items: center;
}

@font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url(./fonts/b-bold.otf) format('woff');
  font-weight: bold;
}

.card-subtitle {
  font-size: 1.1em;
  width: 80%;
}

.card {
  border: none;
  box-shadow: 1px 1px 5px #ee45402e;
  border-radius: 15px;
  position: relative;
  transition: all 0.2s;
}


.card:hover {
transform: scale(1.01);
cursor: pointer;
box-shadow: 1px 1px 10px #ee45402e;
}

.card:active {
transform: scale(0.99);
}

#sign-in-here {
  text-decoration: underline;
}

.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.back-arrow {
  height: 50px;
  width: 50px;
  opacity: 0.1;
  position: relative;
  left: -15px;
  top: -30px;
  transition: opacity 0.2s;
}

.back-arrow:hover {
  opacity: 0.2;
  cursor: pointer;
}

::-webkit-scrollbar {
  display: none;
}

.btn-secondary {
  background: #ee454096;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  transition: all 0.1s;
}

.number {
  color: #807f8d;
}

.btn-secondary:active {
  transform: scale(0.96) !important;
}

.navbar>.container-fluid {
  padding: 0;
  /* add blur */
}

.Collapsible__contentInner>.card {
  background: none !important;
}

but {
  --bs-link-color: #ee454096;
  --bs-link-hover-color: #000000;
}


.nav-pills {
  --bs-nav-pills-link-active-bg: #ee454096;
}

.nav-link {
  border-radius: 15px !important;
}


.card {
  padding: 10px;
  margin-bottom: 20px;
}

.locked {
  color: transparent;
  text-shadow: 0 0 0 #fff;
}

.modal {
  color: black !important;
}

.modal-content svg {
  fill: lightgrey !important;
}

.unlocked {
  font-weight: bold;
  text-shadow: 0 0 0px rgba(0,0,0);
  transition: all 1s;
}

.revealing-name {
  transform: scale(0.9);
  opacity: 0.5;
}

.reveal-names-button {
  background: none;
  font-weight: bold;
  padding: 0 10px;
  padding-bottom: 2px;
  margin-left: 5px;
  color: #ee4540d6;
}

.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.score-board p {
  text-align: center;
}

.landing a {
  text-decoration: none;
}

.landing h1 {
  font-size: 4em;
}

/* .landing h1, .landing>.row {
  color: white;
  padding: 15px 30px 15px 30px;
  border-radius: 30px;
  background: radial-gradient(black, black, rgba(0, 0, 0, 0.583), transparent , transparent);
  text-shadow: 0px 0px 5px black;
}

.landing>.row:last-child {
  background: radial-gradient(black, black, rgba(0, 0, 0, 0.583), transparent , transparent);
}

.landing a {
  color: white;
  font-weight: bold;
  transition: all 300ms;
}
.landing a:hover {
  text-shadow: 0 0 10px #0698a5,
               0 0 30px #0698a5,
               0 0 80px #0698a5,
               0 0 120px #0698a5,
               0 0 200px #0698a5;
}*/

.Collapsible__trigger svg {
  opacity: 0.35;
  margin-right: 15px;
  transition-property: transform;
  transition-duration: 0.3s;  
}

.Collapsible__trigger.is-closed svg {
  transform: rotate(0deg);
}

.Collapsible__trigger.is-open svg {
  transform: rotate(180deg);
}

.card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  background-color: #ffffff52;
}

.class-year {
  font-weight: bold;
}

.friend {
  margin-top: 25px;
  height: 50px;
  display: flex;
  align-items: center;
}

.friend h5 {
  margin: 0px;
}

.friend-box {
  overflow: hidden;
}

.friend-box ul {
  position: absolute;
  right: 40px;
  top: 40px;
  opacity: 1.0;
}

.friend-loading {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.friend-info-loading {
  width: 100%;
}

.friend-actions-loading {
  width: 50%;
  text-align: right;
}



.no-results {
  text-align: center;
  padding: 40px;
  opacity: 0.4;
}

h2>.LoaderDots_root__g4SLZ {
  margin-top: 7px !important;
  margin-left: 15px !important;
}

.friend-search {
  margin-top: 20px;
  border-radius: 15px;
  width: 100%;
  height: 51px;
  min-height: 51px;
  padding-left: 20px;
  font-weight: bold;
  font-size: 1.3em;
  outline: none;
  background: #130f29;
  border: none;
  color: white;
}

.friend-info p {
  margin: 0;
}

.friend:hover .friend-actions {
  opacity: 1;
  cursor: pointer;
}

.friend-actions {
  display: flex;
  margin-left: auto;
}

.nav {
  align-items: center;
}

.navbar-toggler {
  border: 1px solid rgba(255, 255, 255);
}

.poll-footer-action {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.poll-footer-action div {
  margin-right: 15px;
}

.navbar-text, .navbar-text h4 {
  font-weight: bold !important;
}

.poll-options ul, .custom-poll-options ul {
display: flex;
flex-wrap: wrap;
padding: 0;
margin: 0;
}

.custom-poll-options .your-elem {
overflow: hidden;
position: relative;
width: 48%;
margin: 1%;
background: white;
box-shadow: 0px 0px 0px #ee464014;
border: 3px solid rgb(230, 149, 143, 0.15);
background: #ffffff52;
padding: 0;
font-size: 1.5em;
padding-left: 25px;
border-radius: 18px;
margin-bottom: 15px;
font-weight: bold;
height: 60px;
display: flex;
align-items: center;
justify-content: space-between;
transition: all 0.2s;
user-select: none;
opacity: 0.75;
}

.custom-poll-options li:hover {
  border: none !important;
}

.custom-poll-options li {
position: relative;
width: 48%;
margin: 1%;
box-shadow: 0px 0px 0px #ee464014;
background: transparent;
padding: 0;
font-size: 1.2em;
border-radius: 18px;
margin-bottom: 15px;
font-weight: bold;
height: 60px;
display: flex;
align-items: center;
justify-content: space-between;
transition: all 0.2s;
user-select: none;
}


.custom-option__menu {
overflow: hidden !important;
border-radius: 15px !important;
box-shadow: 1px 1px 100px #ee45402e !important;
text-transform: lowercase !important;
border: 1px solid rgba(0, 0, 0, 0.069);
text-align: left;
z-index: 9999 !important;
}

.custom-option__menu h6 {
  font-weight: bold;
  color: white !important;
  margin: 0;
}

.custom-recipient__menu {
  overflow: hidden !important;
  border-radius: 15px !important;
  box-shadow: 1px 1px 100px #ee45402e !important;
  text-transform: lowercase !important;
  border: 1px solid rgba(0, 0, 0, 0.069);
  z-index: 9999 !important;
  font-weight: bold;
}

.focused-option {
  z-index: 9999 !important;
}

.custom-option__option {
  padding: 10px 35px !important;
  color: white !important;
  opacity: 1.0 !important;
}

.custom-option__option h6 {
  opacity: 1.0 !important;
}

.custom-option__single-value h6 {
  color: white;
  font-weight: bold;
  margin: 0;
  opacity: 1.0 !important;
}

.custom-option__control {
  text-transform: lowercase !important;
  text-align: left !important;
  font-weight: bold !important;
  opacity: 1.0 !important;
}

.custom-recipient__control {
text-transform: lowercase !important;
}

.custom-option__placeholder {
opacity: 0.3 !important;
}

.custom-question-container {
margin: 0px 10px 10px 10px;
text-align: center;
font-weight: bold;
font-size: 1.35em;
}


.custom-question__menu {
  font-size: 0.85em;
  font-weight: bold;
}

.custom-question__control {
padding: 10px !important;
box-shadow: none !important;
background: transparent !important;
border: 1px solid #80808024 !important;
}

.custom-question__control:hover {
transform: scale(1.02);
}

.custom-question__placeholder {
color: black !important;
}


.custom-recipient-container {
  width: 350px;
  margin-top: 10px;
}

.footer-layout-group {
  margin-right: 10px;
}

.custom-recipient__control {
  font-weight: bold !important;
}

.custom-recipient__value-container {
  padding: 0px !important;
}

.custom-recipient__control {
  padding: 0px;
  padding-left: 15px;
  font-size: 1.1em;
}

.add-friends-select__placeholder {
  color: rgba(0, 0, 0, 0.113);
  font-weight: bold;
}

.add-friends-select__menu {
  overflow: hidden !important;
  border-radius: 15px !important;
  box-shadow: 1px 1px 100px #ee45402e !important;
  text-transform: lowercase !important;
  overflow: hidden;
  z-index: 9999 !important;
}

.add-friends-select {
  font-weight: bold !important;
  font-size: 1.1em !important;
  z-index: 0 !important;
}

.add-friends-select__control {
  background-color: #ffffff52;
  transition: all 0.5s !important;
  z-index: 9999 !important;
}

.add-friends-select__control--is-focused {
  box-shadow: 0 0 0 max(1000vh, 1000vw) rgba(0, 0, 0, 0.85) !important;
  z-index: 9999 !important;
}

.add-friends button {
  z-index: 0 !important;
}

.elem {
  border: 3px solid rgb(230, 149, 143, 0.15);
}

.potential-friends ul {
  padding: 0;
  margin: 0;
  list-style: none;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}

.potential-friends-list {
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}

.potential-friends-list h5 {
  font-size: 1em !important;
  font-weight: bold;
}

.potential-overlay {
  position: absolute;
  top: 15px;
  right: -10px;
  height: 50px;
  width: 10%;
  transition: all 0.5s;
}

.results-status h6, .suggestions-status h6 {
  font-weight: bold;
  margin: 0;
  display: flex;
  opacity: 0.2;
  align-items: center;
  justify-content: center;
}

.suggestions-status {
  border-radius: 10px;
  margin-right: 10px;
}

.results-status {
  border-radius: 10px;
  margin-left: 10px;
}

.dots {
  position: absolute;
  width: 100%;
  bottom: 12px;
  display: flex;
  justify-content: center;
}

.dot.active-dot svg {
  fill: white !important;
}

.dot {
  margin: 0 3px;
}

.dot svg {
  height: 7px;
  width: 7px;
  fill: #ffffff24 !important;
}

.status-active {
  background-color: #0d091da3 !important;
}

.status-active h6 {
  opacity: 0.8 !important;
}

h6 svg {
  margin-left: 4px;
}

.visibility-score {
  margin-left: auto;
}

.results-underlay {
  position: fixed;
  height: 60px;
  transition: all 0.5s;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  background: #181229;
  border-top: 2px solid #27243c;
  color: white;
  z-index: 1 !important; 
  /* border-radius: 0px 0px 15px 15px; */
}

.results-underlay-actions {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0px 15px;
}

.results-underlay-actions b {
  margin-left: auto;
}

.results-underlay-actions p {
  margin: 0;
}

.delete-friend svg {
  height: 15px !important;
  width: 15px !important;
}

.vis-text {
  opacity: 0.3;
}

.vis-span {
  opacity: 0.7;
  font-weight: bold;
}

.results-underlay-actions button {
  margin-left: auto;
  border-radius: 10px;
}

.add-more-friends {
  text-align: right;
  margin-left: auto;
}

.add-more-friends button {
  background: none;
  border: 3px solid #232039;
  border-radius: 20px;
  font-weight: bold;
  padding: 5px 20px;
  color: #d0cfd4;
  font-size: 0.9em;
}

.add-more-friends svg {
  margin-left: 5px;
  fill: #d0cfd4 !important;
}

.potential-friends li {
  min-width: 250px;
  font-weight: bold;
  background-color: #120f29;
  font-size: 1.1em;
  padding: 3px 20px;
  margin-right: 10px;
  border-radius: 15px;
  display: inline-block;
}

/* get the last element in potential-friends li */
.potential-friends li:last-child {
  margin-right: 200px;
}

.skip-wait {
  border-top: 2px solid #2320396e;
  padding-top: 20px;
  padding-bottom: 30px;
  margin-top: 25px;
}

.send-invite svg {
  stroke: white !important;
  fill: white !important;
  height: 20px !important;
  width: 20px !important;
}

.skip-wait h5 {
  font-weight: bold;
    opacity: 0.3;
}

.potential-friends {
  display: flex;
  flex-direction: column;
  position: relative;
}

.potential-friend-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.potential-friend-container button {
  margin-left: auto;
  position: relative;
  height: 30px;
  right: -15px;
  border-radius: 10px !important;
  background: #ffffff17 !important;
  transition: transform 0.1s;
}

.potential-friend-container button:active {
  transform: scale(0.85) !important;
}

.plus {
  font-weight: bold;
  font-size: 1.5em;
  opacity: 0.75;
  position: absolute;
  top: -4px;
  left: 10px;
}

.bin {
  font-weight: bold;
  /* font-size: 1.5em; */
  opacity: 0.6;
  position: absolute;
  top: 3px;
  left: 7px;
}

.bin>svg {
  fill: #ffffff70;
}

.potential-friend-actions>button {
  background: rgb(230, 149, 143, 0.15) !important;
}

.potential-label {
  color: rgba(255, 255, 255, 0.8);
  margin-right: auto;
}

.custom-elem:hover {
  border: none !important;
}

.custom-option-container {
  border-radius: 15px;
  transition: all 0.2s !important;
}

.poll-options li:hover, .custom-option-container:hover {
cursor: pointer;
box-shadow: 0px 0px 5px #ee45402e !important;
}

.reactour__helper {
  color: black !important;
}

.custom-poll-options li:hover {
  border: none !important;
}

/*.poll-options li:active {
transform: scale(0.9);
}*/

.poll-loading {
position: absolute;
display: block;
justify-content: right;
align-items: center;
border-radius: 20px 2px 2px 20px;
top: 0px;
left: 0px;
height: 100%;
background: rgb(230, 149, 143, 0.15);
opacity: 1.0;
transition: all 1.5s, background 0.05s ease-out;
}

.poll-label {
color: #181515d4;
z-index: 1;
}

.chosen {
z-index: -1;
box-shadow: 0px 0px 5px #00000066 inset !important;
}

.chosen span {
  transition: all 1s;
}

.poll-percent {
position: absolute;
right: 0px;
padding-right: 15px;
color: #ee464043;
opacity: 0.3;
}

.react-joyride__tooltip button {
  padding: 5px 25px !important;
  font-size: 1.0em !important;
}

.search-status {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.suggestions-status, .results-status {
  padding: 8px;
  text-align: center;
  width: 100%;
  transition: all 0.2s;
}

.suggestions-status:hover, .results-status:hover {
  cursor: pointer;
  background: #ffffff1f;
}

.chosen-percent {
  opacity: 1.0;
}

.hidden {
opacity: 0.0;
}

.potential-friends-header {
  display: flex;
}

.potential-friends-header h5:nth-child(2) {
  margin-left: auto;
  opacity: 0.15 !important;
}

.down-icon svg {
  position: relative;
  height: 30px;
  width: 30px;
  opacity: 0.95;
  fill: none !important;
  animation: oscillate 3s infinite;
}

/* .down-icon svg should animate between 0.5 and 1.0 opacity */
.timeout-height {

}

.poll {
  position: relative;
  background-color: #ffffff52;
  border-radius: 15px;
  padding: 25px;
  /* -webkit-filter: blur(0px); */
  /* filter: blur(0px); */
  opacity: 1.0;
  transition: all 0.5s;
}

.poll-config {
  color: #43405b;
  display: flex;
  align-items: center;
}

.poll-config p {
  margin: 0;
  margin-left: 10px;
}

.poll-config svg {
  height: 25px;
    width: 25px;
    fill: #43405b !important;
    border-radius: 3px;
}

.poll-config:hover {
  cursor: pointer;
}

.poll h2 {
  margin: 0 auto;
  margin-bottom: 1rem;
  margin-top: 10px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  text-transform: lowercase;
}

.poll-supplement {
  display: flex;
  margin-top: 10px;
}

.friend-actions svg {
  height: 30px;
  width: 30px;
}

.transition {
  /*-webkit-filter: blur(5px);
  filter: blur(5px);*/
  opacity: 0.5;
}

/* only do the below if we are in desktop mode */
@media (min-width: 769px) {
  .friend-finder {
    animation: delayed-height 1s ease-in-out 1s;
    animation-fill-mode: forwards;
  }

  .timeout-height {
    animation: delayed-height-timeout 1s ease-in-out 1s;
    animation-fill-mode: forwards;
  }
}



.css-vqqxrc-control:hover {
transform: scale(1.02);
transition: all 0.2s;
}

.poll-footer {
display: flex;
align-items: center;
justify-content: space-between;
margin: 10px;
}

.custom-poll-footer {
  display: flex;
  align-items: end; 
  justify-content: space-between;
  margin: 10px;
}

.custom-recipient__placeholder {
  opacity: 0.5;
}

.poll-footer span, .custom-poll-footer span {
  color: #ee454043;
}

.close-tip {
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.05;
}

.footer-action-group button, .poll-footer button {
  border-radius: 10px;
  padding: 5px 25px;
  font-size: 1.0em;
  background-color: #ee464080;
}

.poll-options {
  min-height: 200px !important;
}

.friend-finder {
  margin-top: 15px;
}

@media only screen and (max-width: 768px) {
  section.kappa, .poll-options li, .custom-poll-options li, .custom-poll-options .your-elem {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 15px !important;
  }

  .vote-info {
    flex-direction: column;
    text-align: center;;
  }

  .vote-time {
    margin-left: 0 !important;
  }

  .tip {
    text-align: center !important;
  }

  .tip-content {
    flex-direction: column;
  }

  .tip-content h5 {
    font-size: 1.3em !important;
    margin-bottom: 15px !important;
    margin-top: 10px !important;
  }

  .tip-content h6 {
    margin-bottom: 10px !important;
  }

  .tip-text {
    padding: 0 !important;
  }

  .tip-content span {
    margin: 0 !important;
  }

  .tip-gif {
    margin-top: 15px;
  }

  .tip-next {
    margin: 0 !important;
  }

  .tip-previous {
    margin: 0 !important;
  }

  .tip-next svg, .tip-previous svg {
    fill: white !important;
    width: 25px !important;
    height: 25px !important;
  }

  .friend-finder {
    animation: delayed-height-mobile 1s ease-in-out 1s !important;
    animation-fill-mode: forwards !important;
  }

  .timeout-height {
    animation: delayed-height-timeout-mobile 1s ease-in-out 1s !important;
    animation-fill-mode: forwards !important;
  }

  .add-friends-select__control {
    font-size: 1.1em !important;
  }

  .poll-results-header {
    flex-direction: column-reverse;
  }
  
  .poll-results-header h4 {
    margin-top: 60px !important;
    margin-bottom: 40px !important;
  }

  .devious {
    margin-left: 0;
  }

  .poll-footer span {
    width: 100%;
    padding-right: 20px;
  }

  .poll-footer span {
    width: 50%;
  }

  .custom-poll-footer {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  .custom-poll-footer .footer-layout-group {
    margin-right: 0px;
    width: 100%;
  }

  .custom-question-container {
    margin-left: 0px;
    margin-right: 0px;
  }

  .custom-recipient-container {
    width: 100%;
    margin-bottom: 15px;
  }

  .footer-action-group {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .footer-action-group button {
    width: 100%;
    height: 50px;  
  }

  .score-board {
    padding: 15px;
  }
}

.add-friends {
  display: flex;
  transition: all 0.2s;
  position: relative;
  margin-top: 20px;
}

.add-friends:hover {
  cursor: pointer;
}

.css-b62m3t-container {
  flex: 1;
}

.poll-results h4 {
  font-weight: bold;
  opacity: 0.1;
  margin: 0;
  z-index: -1 !important;
}

.result {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 60px;
}

.result-breakdown h5 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.result-header {
  z-index: -1;
}

.result-header h1 {
  font-weight: bold;
  text-transform: lowercase;
  opacity: 0.3;
  font-size: 3em;
  margin: 0;
}

.people {
  position: relative;
  left: -3px;
  font-size: 0.5em;
  opacity: 0.7;
}

.result h2 {
  font-weight: bold !important;
  font-size: 2.15em !important;
  text-transform: lowercase !important;
  margin: 0 !important;
}

.shuffle p {
  line-height: 16px;
}

.shuffle svg {
  fill: none !important;
  height: 34px;
  width: 34px;
  border: none !important;
  opacity: 0.3;
  transition: all 0.4s !important;
}

.spinning svg {
  transform: rotate(360deg) scale(0.8) !important;

}

.shuffle:hover {
  cursor: pointer;
}

.result-chosen {
  padding: 5px 15px;
  background: transparent !important;
  border-radius: 25px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.2s;
}

.result-chosen:active {
  transform: scale(0.95);
}

.result-chosen:hover {
  border: 3px solid #ffffff36 !important;
  cursor: pointer;
}

.result h5 {
font-size: 1em;
font-weight: bold;
margin-top: 5px;
}

.result-details {
  display: flex;
}


/* make this only for mobile */
@media only screen and (max-width: 468px) {
body {
  font-size: 4.0vw;
}
}

/* loader */

.loader {
width: 48px;
height: 48px;
position: relative;
}

.loader:before {
content: '';
width: 48px;
height: 5px;
background: #f0808050;
position: absolute;
top: 60px;
left: 0;
border-radius: 50%;
animation: shadow324 0.5s linear infinite;
}

.loader:after {
content: '';
width: 100%;
height: 100%;
background: #f08080;
position: absolute;
top: 0;
left: 0;
border-radius: 4px;
animation: jump7456 0.5s linear infinite;
}

@keyframes oscillate {
  0% {
   top: 0px;
  }

  50% {
    top: 10px;
  }

  100% {
    top: 0px;
  }
}

@keyframes jump7456 {
15% {
  border-bottom-right-radius: 3px;
}

25% {
  transform: translateY(9px) rotate(22.5deg);
}

50% {
  transform: translateY(18px) scale(1, .9) rotate(45deg);
  border-bottom-right-radius: 40px;
}

75% {
  transform: translateY(9px) rotate(67.5deg);
}

100% {
  transform: translateY(0) rotate(90deg);
}
}

@keyframes shadow324 {

0%,
  100% {
  transform: scale(1, 1);
}

50% {
  transform: scale(1.2, 1);
}
}

.loading-emoji {
animation: spin 3s ease-in-out infinite, size 2s cubic-bezier(0.35, 0, 0.25, 1) infinite;
font-size: 2em;
opacity: 0.6;
}

.new-sugg {
  position: relative;
  font-size: 0.75em;
  background: #8e2933;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  bottom: 2px;
  margin-left: 10px;
}

.static-emoji {
font-size: 2em;
opacity: 0.6;
}

@keyframes spin {
0% { transform: rotate(0deg) scale(1); }
50% { transform: rotate(720deg) scale(1.2); }
100% { transform: rotate(1440deg) scale(1);  }
}

.sr-only {
animation: changeText 2s ease-in-out infinite;
}

@keyframes changeText {
0% { content: "Initial text"; }
50% { content: "Changing text"; }
100% { content: "Initial text"; }
}

.poll-overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: left;
  background-color: #ffffff52;
  flex-direction: column;
  border-radius: 15px;
}

.delayed-blur {
filter: blur(0);
transform: translate3d(0, 0, 0);
transition: filter 1s ease-in-out 1s;
animation: delayed-blur 1s ease-in-out 1s;
animation-fill-mode: forwards;
}

.fast-blur {
  filter: blur(0);
  transition: filter 2s ease-in-out;
  animation: delayed-blur 2s ease-in-out;
  animation-fill-mode: forwards;
}

.unveal {
  opacity: 0;
  transition: opacity 1s ease-in-out 1s;
  animation: delayed-reveal 1s ease-in-out 1s;
  animation-fill-mode: forwards;
  z-index: 1;
  padding: 0px 20px;
}

@keyframes delayed-reveal {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes delayed-blur {
  0% { filter: blur(0); }
  100% { filter: blur(50px); }
}

@keyframes delayed-height-mobile {
  0% { height: 505px; }
  100% { height: 80vh; }
}

@keyframes delayed-height {
  0% { height: 360px; }
  100% { height: 80vh; }
}

@keyframes delayed-height-timeout-mobile {
  0% { height: 505px; }
  100% { height: 70vh; }
}

@keyframes delayed-height-timeout {
  0% { height: 360px; }
  100% { height: 60vh; }
}


footer {
  text-align: center;
  margin-top: 55px;
  opacity: 0.1;
}

.wrapper {
  position: relative;
}

.react-card-flipper {
  height: 100%;
  position: absolute;
  width: 100%;
  perspective: 2000px;
}

.larr {
  position: relative;
  top: 3px;
  left: -6px;
}

.poll-progress {
  position: absolute;
  height: 10px;
  border-radius: 5px;
  top: 0;
  left: 0;
  transition: all 0.2s;
}

.chosen-by-title {
  color: #423e55;
  margin-right: 15px;
  margin-bottom: 10px;
}

.tip {
  position: relative;
  width: 100%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  transition: all 0.5s;
  text-align: left;
}

#flirt-crossed {
  margin: 0;
  text-decoration: line-through;
}

.tip-text {
  flex: 1.1;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.start-button {
  font-weight: bold;
  font-size: 1.15em;
}

.pulse {
  display: block;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 20px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 20px rgba(204,169,44, 0);
      box-shadow: 0 0 0 20px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

.lds-ellipsis div {
  background: white !important;
}

.tip-content {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 28px;
  display: flex;
}

.tip-content span {
  font-weight: bold;
  margin-bottom: 3px;
  margin-right: 8px;
  opacity: 0.8;
}

#cheeky {
  margin: 0;
  opacity: 0.5;
  font-size:  0.9em;
}

.tip-content h6 {
  margin: 0px;
  font-size: 0.9em !important;
  opacity: 0.5;
  margin-bottom: 0;
  font-weight: bold;
}

.tip-content h5 {
  font-weight: bold;
  font-size: 1.5em;
  margin: 0;
}

.tip-gif {
  text-align: right;
  flex: 1.0;
}

.tip-gif {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0px 1px 15px #090710;
  margin-top: 15px;
  margin-bottom: 15px;
}

.tip-next {
  margin-left: 6px;
  margin-right: 5px;
}

.tip-previous {
  margin-right: 6px;
  margin-left: 5px;
}

.tip-num {
  font-weight: bold;
  font-size: 1.5em;
  margin-right: 10px;
}

.tip-next svg polyline, .tip-previous svg polyline {
  stroke: white !important;
}

.tip-next svg, .tip-previous svg {
  fill: white !important;
  width: 35px;
  height: 35px;
}

.tip-next:hover, .tip-previous:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.tip-next svg:active, .tip-previous svg:active {
  transform: scale(0.9);
}

.tip-next:focus svg polyline, .tip-previous:focus svg polyline {
  stroke: #423e55 !important;
}

.tip:hover {
  cursor: pointer;
}

.hide-tip {
  transform: scale(0.0);
  height: 0px;
  margin-bottom: 0px;
}

.fire {
  font-weight: bold;
  font-size: 1.2em;
}


#tip {
  position: relative;
  bottom: 2px;
  opacity: 0.3;
}

/* convert all css to scss */

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');

/* css switch */

/* The switch - the box around the slider */
.switch {
  font-size: 17px;
  position: absolute;
  top: 10px;
  left: 0px;
  display: inline-block;
  width: 3.5em;
  height: 2em;
  z-index: 2;
 }

 #my-tooltip {
  width: 150px;
  padding-bottom: 25px;
  z-index: 9999 !important;
 }
 
 /* Hide default HTML checkbox */
 .switch input {
  opacity: 0;
  width: 0;
  height: 0;
 }
 
 /* The slider */
 .switch-wrapper {
  position: relative;
  z-index: 1;
  transition: all 0.2s;
 }

 .poll-config-label {
  color: #ffffff2e;
    position: relative;
    left: 70px;
    top: 15px;
 }

 .poll-config-label p {
  margin: 0;
 }

 .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ee464060;
  transition: .4s;
  border-radius: 30px;
  transition: all 0.2s;
 }

 .slider:hover {
  transform: scale(1.1);
 }
 
 .slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.3em;
  bottom: 0.3em;
  background-color: white;
  transition: .4s;
  z-index: 2;
 }

 .react-card-back {
  /* backface-visibility: hidden; for all browsers */
  backface-visibility: hidden !important;
  /* Safari */
  -webkit-backface-visibility: hidden !important;
  /* Firefox */
  -moz-backface-visibility: hidden !important;
  /* IE */
  -ms-backface-visibility: hidden !important;
  /* Opera */
  -o-backface-visibility: hidden !important;
 }

 .on:before {
  transform: translateX(1.5em);
 }

.off:before {
  transform: translateX(0em);
}

.slider>.off {
  background: red !important;
}

 .sun {
  position: absolute;
  right: 9px;
  top: 4px;
  z-index: 0;
 }

  .moon {
    position: absolute;
    left: 8px;
    top: 4.5px;
    z-index: 0;
  }

  .Typewriter__cursor {
    margin-left: 3px !important;
  }

.kappa .c-btn-tetrary {
  background-color: #191634 !important;
  box-shadow: 0 4px 8px -1px rgb(0 0 0 / 15%), inset 0 -2px 5px -1px rgb(19 15 41), 0 -3px 12px -1px rgb(19 15 41), inset 0 3px 4px -1px rgb(78 75 95 / 50%), inset 0 0 4px 1px rgb(19 15 41), inset 0 1.1em 1.4em 0 rgb(35 32 57);
}

button {
  outline: none;
}

.poll-options li {
  position: relative;
  overflow: hidden;
  width: 48%;
  margin: 1%;
  background: white;
  box-shadow: 0px 0px 0px #ee464014;
  background: #ffffff52;
  padding: 0;
  font-size: 1.5em;
  padding-left: 25px;
  border-radius: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s, transform 0.05s;
  user-select: none;
}

button.c-btn.c-btn-tetrary {
  width: 100%;
}

section.kappa {
  width: 48%;
  margin: 1%;
}

.kappa .c-btn {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  text-align: left;
  color: white;
  font-weight: bold;
  margin: 0 !important;
  display: inline-block;
  margin: 6px;
  font-size: inherit;
  line-height: 1.42;
  padding: 0.6em 1.2em;
  border-width: 0;
  border-style: solid;
  background: transparent;
  color: white;
  text-align: left;
  font-size: 1.5em;
  cursor: pointer;
  user-select: none;
  vertical-align: bottom;
  text-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s, transform 0.05s;
}

.kappa .c-btn:active {
  transition-duration: 0.1s;
  transform: scale(0.95);
}

.kappa .c-btn-tetrary:active {
  color: white;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px -1px rgb(0 0 0 / 50%), inset 0 -2px 5px -1px rgb(19 15 41), 0 -3px 12px -1px rgb(35 32 57), inset 0 3px 4px -1px rgb(35 32 57), inset 0 0 4px 1px rgb(35 32 57), inset 0 1.1em 1.4em 0 rgb(19 15 41);
}

.chosen-elem {
  box-shadow: 0 4px 8px -1px rgb(0 0 0 / 50%), inset 0 -2px 5px -1px rgb(19 15 41), 0 -3px 12px -1px rgb(35 32 57), inset 0 3px 4px -1px rgb(35 32 57), inset 0 0 4px 1px rgb(35 32 57), inset 0 1.1em 1.4em 0 rgb(19 15 41) !important;
  color: white;
  transform: scale(0.95);
}

.react-confirm-alert-body {
  font-family: 'MyFont' !important;
  text-align: center !important;
}

.react-confirm-alert-button-group {
  justify-content: space-around !important;
}

.react-confirm-alert-button-group > button {
  margin: 0 !important; 
  font-size: 1em !important;
  color: white !important;
  background: #ee454096 !important;
  width: 100%;
}

.feed-item {
  display: flex;
    align-items: center;
}

.feed-header {
  display: flex;
  padding: 0;
  height: 125px;
  align-items: center;
}

.feed-ul {
  padding: 0;
  list-style: none;
  text-transform: lowercase;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.answer-ul {
  padding: 0;
  margin-left: 26px;
  list-style: none;
  text-transform: lowercase;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.feed-box {
  padding: 15px 15px 15px 25px;
  border-radius: 15px;
  background: #03001c;
  border: 3px solid #232039;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.your-item {
  background: #1a1731 !important;
}

.feed-data {
  display: flex;
  flex-direction: column;
}

.vote-box {
  position: relative;
  left: -10px;
  /* width: 50%; */
  padding: 10px 15px 15px 15px;
  flex: 2;
  /* margin-left: auto; */
  background: rgba(35, 32, 57, 0);
  border-radius: 15px;
  border: 3px solid #232039;
  border-style: dashed;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.notifications {
  position: absolute;
  font-weight: bold;
  /* height: 20px; */
  /* width: 20px; */
  background: #8e2933;
  border-radius: 15px;
  padding: 2px 5px 0px 5px;
  font-size: 0.8em;
  top: -1px;
  left: 50%;
  box-shadow: 0px 0px 5px #261c2a;
}

.feed-item {
  border-left: 3px solid #232039;
  padding-bottom: 40px;
  padding-left: 3px;
}

.vote-info {
  display: flex;
}

.vote-answer-box .upvote svg {
  height: 15px;
}

.vote-answer-box button {
  background: #232039;
  border-radius: 7px;
  padding: 0;
  width: 30px;
  height: 30px;
  margin-left: auto;
}

.vote-time {
  opacity: 0.5;
  position: absolute;
    top: -30px;
    right: 0;
}

.feed-item:first-child {
  padding-top: 0px;
}

.feed-item:last-child {
  padding-top: 0px;
  margin-bottom: 20px;
  border-left: 5px solid transparent;
  align-items: start;
}

.arrow-box {
  flex: 0.25;
  position: relative;
  height: 80px;
  width: 10px;
  left: -7.5px;
}

.feed-item:last-child .arrow-box {
  left: -9.5px;
}

.voter {
  font-size: 1.35em;
}

.vote-type {
  font-size: 1.35em;
  margin-bottom: 5px;
}

.vote-answer-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.navbar-text h4:hover {
  cursor: pointer;
}

.votee {
  flex: 0.9;
  color: white;
  border-radius: 7px;
  font-size: 1.2em;
  text-align: left;
  margin-left: 5px;
}

.num-votes, .vote-type, .vote-time {
  font-weight: bold;
  color: #b5b5b8;
}

.answer {
  margin-right: 5px;
  opacity: 0.3;
  font-size: 0.8em;
}

button, a {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.like .like-btn:active {
  color: #06237a;
  box-shadow: 0 4px 8px -1px rgb(0 0 0 / 50%), inset 0 -2px 5px -1px rgb(19 15 41), 0 -3px 12px -1px rgb(35 32 57), inset 0 3px 4px -1px rgb(35 32 57), inset 0 0 4px 1px rgb(35 32 57), inset 0 1.1em 1.4em 0 rgb(19 15 41);
  transition-duration: 0.1s;
  transform: scale(0.90);
}

.like-btn-active {
  color: #06237a;
  box-shadow: 0 4px 8px -1px rgb(0 0 0 / 50%), inset 0 -2px 5px -1px rgb(19 15 41), 0 -3px 12px -1px rgb(35 32 57), inset 0 3px 4px -1px rgb(35 32 57), inset 0 0 4px 1px rgb(35 32 57), inset 0 1.1em 1.4em 0 rgb(19 15 41);
  transition-duration: 0.1s;
  transform: scale(0.90);
}

.like-btn:active svg {
  transition-duration: 0.1s;
  opacity: 0.5;
  transform: rotate(5deg)
}


.like .like-btn-tetrary {
  box-shadow: 0 4px 8px -1px rgba(0, 0, 0, 0.15), inset 0 -2px 5px -1px rgb(19, 15, 41), 0 -3px 12px -1px rgb(19, 15, 41), inset 0 3px 4px -1px rgb(17 13 39 / 0%), inset 0 0 4px 1px rgb(19, 15, 41), inset 0 1.1em 1.4em 0 rgb(35, 32, 57);
}

.like {
  margin-left: auto;
}

.like .like-btn {
  border-width: 0;
  border-style: solid;
  background: transparent;
  border-radius: 0.6em;
  cursor: pointer;
  user-select: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  transition: box-shadow 0.2s cubic-bezier(0.23, 1, 0.32, 1), transform 0.1s cubic-bezier(0.23, 1, 0.32, 1);
}

.mail-btn:active svg {
  transition-duration: 0.1s;
  opacity: 0.5;
  transform: rotate(5deg)
}


.mail .mail-btn-tetrary {
  box-shadow: 0px 0px 5px rgb(3, 0, 28)
}

.mail .mail-btn:active {
  color: #06237a;
  box-shadow: 0 4px 8px -1px rgb(0 0 0 / 50%), inset 0 -2px 5px -1px rgb(19 15 41), 0 -3px 12px -1px rgb(35 32 57), inset 0 3px 4px -1px rgb(35 32 57), inset 0 0 4px 1px rgb(35 32 57), inset 0 1.1em 1.4em 0 rgb(19 15 41);
  transition-duration: 0.1s;
  transform: scale(0.90);
}


.feed-action {
  margin-left: auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: inherit;
}

.mail .mail-btn {
  height: 30px;
  width: 30px;
  padding: 0;
  border-width: 0;
  border-style: solid;
  background: transparent;
  border-radius: 0.6em;
  cursor: pointer;
  user-select: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  transition: box-shadow 0.2s cubic-bezier(0.23, 1, 0.32, 1), transform 0.1s cubic-bezier(0.23, 1, 0.32, 1);
}

.poll-count {
  font-size: 1.3em;
}

.guide-subtitle {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  text-align: left;
  color: rgb(41, 36, 78);
}

.your-summary {
  border: 3px solid #232039;
  border-radius: 15px;
  padding: 15px 25px;
  text-align: center;
  border-style: dashed;
  position: relative;
}

.your-summary h4 {
  position: absolute;
    font-weight: bold;
    color: #28244eb4;
    top: -15px;
    width: 100%;
    left: 0;
    background: linear-gradient(90deg, transparent, #03001c, #03001c, transparent);
}

.guide-super {
  font-size: 1.25em;
  margin: 0;
  color: #b5b5b8;
  font-weight: bold;
  line-height: 40px;
} 

.total-hearts, #total-polls {
  font-size: 1.2em;
  font-weight: bold;
  color: white !important;
}

.guide-title {
  font-weight: bold;
  color: rgb(41, 36, 78);
  margin-bottom: 5px;
  text-align: left;
}

.feed-header-action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 0px;
  margin-top: 15px;
  margin-left: auto;
}

.feed-header-action h3:nth-child(1) {
  padding-right: 10px;
}

.feed-header-action .active-tab {
  opacity: 0.8;
  transform: scale(1.05);
}

.feed-header-action :not(.active-tab) {
  opacity: 0.2;
  transform: scale(0.9);
  transition: all 0.2s;
}

.feed-header-action h3 {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 1em;
  opacity: 0.2;
  transition: all 0.2s;
}

.feed-header-action h3:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.feed-header-action h3:active {
  transform: scale(0.9);
}

.more-info {
  text-align: center;
  padding-top: 5px;
}

.more {
  font-size: 0.8em;
  transition: all 0.2s;
}

.more:hover {
  cursor: pointer;
  opacity: 0.6;
}

.totalLikes svg {
  margin-left: 3px;
  margin-bottom: 5px;
  margin-right: 5px;
  height: 25px;
  width: 25px;
  fill: #ff5e6e !important;
  transition: all 0.1s;
}

.totalHearts {
  color: #ff5e6e;
  margin-bottom: 5px;
}

.totalHearts svg {
  margin-bottom: 5px;
  height: 17px;
  width: 17px;
  fill: #ff5e6e !important;
  transition: all 0.1s;
}

.totalLikes {
  color: #ff5e6e;
  margin-left: auto;
}

.totalLikes svg:hover {
  cursor: pointer;
}

.totalLikes svg:active {
  transform: scale(0.8);
}

.unliked svg {
  fill: none !important;
}

.food span {
  transition: all 0.1s linear;
}

.ck-editor__editable {
  min-height: 200px;
  border-radius: 20px;
}

.affirm-notification {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #df414f;
  display: flex;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
}

.affirm-locked-number {
  font-size: 1.25em;
  font-weight: bold;
}